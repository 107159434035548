body {
  background-color: #eeeeee;
}

.canvas {
  touch-action: none;
  width: 100vw;
  height: 100vh;
}

.rectangle {
  position: absolute;
  stroke-width: 1px;
  border-style: solid;
  border-width: 2px;
  height: 100px;
  width: 100px;
}

.toolbar {
  position: fixed;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px;
  border-radius: 8px;
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  background-color: #ffffff;
  user-select: none;
}

.toolbar button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #f8f8f8;
  color: #181818;
  border: none;
  box-shadow:
    0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  margin: 4px;
  font-weight: 500;
  font-size: 12px;
}

.toolbar button:not(:disabled):hover,
.toolbar button:not(:disabled):focus {
  background-color: #ffffff;
}

.toolbar button:not(:disabled):active {
  background-color: #eeeeee;
}

.toolbar button:disabled {
  opacity: 0.5;
}

.loading {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  place-content: center;
  place-items: center;
}

.loading img {
  width: 64px;
  height: 64px;
  opacity: 0.2;
}
